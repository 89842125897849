.button-add-quiz {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 50px;
    min-height: 50px;
}

.quizzes-list-course-name, .quizzes-list-course-finish {
    display: block;
    font-size: 11px;
    text-decoration: none;
}

.quizzes-list-course-name::before {
    content: 'Kurs: ';
    color: #333;
}

.quiz-absolute-right-corner {
    text-align: right;
}

.quiz-absolute-right-corner .btn {
    margin-bottom: 15px;
}

.quiz-absolute-right-corner-scores {
    text-align: right;
}

.quiz-absolute-right-corner-scores p {
    line-height: 0;
    margin-bottom: 5px;
    display: inline-block;
    margin-left: 5px;
    width: 32%;
    text-align: center;
}

.form-add-question label {
    cursor: pointer;
}

.edit-question-right-bottom-cornet {
    text-align: right;
}

.answer-boxes-container button {
    text-transform: none!important;
}

.accordion-button {
    text-transform: unset!important;
}