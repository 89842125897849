.page-footer {
    background-color: #eee;
    font-size: 12px;
    padding: 5px;
}

.page-footer div {
    margin: 0;
    padding: 0;
}

.page-footer a {
    color: var(--black-color);
}