.message-my, .message-contact {
    display: block;
    margin: 10px 0;
    font-size: 21px;
}

.message-my span.badge, .message-contact span.badge {
    padding: 15px;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    max-width: 75%;
    width: auto;
    vertical-align: middle;
}

.message-my span.time, .message-contact span.time {
    font-size: 9px;
    margin: 0 5px;
}

.message-my {
    text-align: right;
}

.message-my span.badge {
    text-align: right;
}
.message-contact span.badge {
    text-align: left;
}


.is-read {
    color: #555;
    font-size: 11px;
}

.message-avatar {
    height: 40px;
    width: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    border-radius: 25px;
    align-self: center;
    justify-self: center;
    vertical-align: middle;
}

.message-avatar img {
    flex-shrink: 0;
    object-fit: cover;
    object-position: center center!important;
    min-width: 100%;
    min-height: 100%;
    max-width: 40px;
}

.message-avatar-my {
    margin-left: 10px;
}

.message-avatar-contact {
    margin-right: 10px;
}

.message-avatar-big {
    height: 75px;
    width: 75px;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    border-radius: 25px;
    align-self: center;
    justify-self: center;
    vertical-align: middle;
}

.message-avatar-big img {
    flex-shrink: 0;
    object-fit: cover;
    object-position: center center!important;
    min-width: 100%;
    min-height: 100%;
    max-width: 75px;
}

.title-of-message {
    display: inline-block;
    vertical-align: middle;
    padding-left: 15px;
}

.added-file {
    border: 1px solid var(--bs-primary);
    margin: 5px;
    border-radius: 25px;
    padding: 10px;
    transition: .2s;
    cursor: pointer;
    line-height: 25px;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.added-file:hover {
    background-color: var(--bs-primary);
    color: #FFF;
}

.files-under-message-container {
    margin-bottom: 20px;
    display: block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.file-under-message {
    font-size: 11px;
    padding: 5px;
    margin: 5px;
    border: 1px solid var(--bs-primary);
    text-decoration: none;
    color: #444;
    transition: .3s;
    display: inline-block;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
}

.file-under-message:hover {
    background-color: var(--bs-primary);
    color: #FFF;
}

.nav-link .badge {
    padding: 4px;
    margin-left: 5px;
}