@import "assets/css/Animations.css";
@import "assets/css/Navbar.css";
@import "assets/css/Content.css";
@import "assets/css/LoaderScreen.css";
@import "assets/css/Messages.css";
@import "assets/css/Files.css";
@import "assets/css/Courses.css";
@import "assets/css/Threads.css";
@import "assets/css/Quizzes.css";
@import "assets/css/Footer.css";

:root {
  --white-color: #FFF;
  --black-color: #000;
  --red-color: red;
  --green-color: green;

}
