.course-box-container {
    position: relative;
}

.course-box {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #eee;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
    color: #222;
    text-decoration: none;
    box-shadow: 0 8px 32px -24px rgba(66, 68, 90, 1)!important;
    transition: .4s;
    padding-bottom: 15px;
}

.course-box:hover {
    background-color: var(--bs-primary);
    color: #FFF;
}

.course-box:active  {
    -moz-transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -o-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}

.course-box-ico {
    background-color: var(--bs-primary);
    padding: 15px;
    font-size: 29px;
    color: #FFF;
    border-radius: 25px;
    margin-top: -25px;
    margin-bottom: 15px;
    transition: .2s;
}

.course-box:hover .course-box-ico {
    transform: scale(1.5, 1.5);
}

.heart-in-box-course {
    position: absolute;
    top: 50px;
    right: 25px;
    font-size: 37px;
    color: red;
    z-index: 1;
}

.jumbotron {
    position: relative;
}

.heart-in-head-course {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 61px;
    color: red;
}

.course-box-name, .course-box-description {
    text-align: center;
}

.left-top-corner-of-course {
    position: absolute;
    top: 45px;
    left: 19px;
}

.left-top-corner-of-course svg {
    transition: .3s;
    cursor: pointer;
    font-size: 21px;
    z-index: 1;
}

.left-top-corner-of-course svg:hover {
    color: var(--bs-primary);
}

.left-top-corner-of-course svg:last-child {
    margin-left: 5px;
}

.select-course-icon div {
    z-index: 11;
}
