.loader-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader-screen div {
    width: 31%;
    text-align: center;
    margin: 0 auto;
}

.loader-screen div h2 {
    color: #FFF;
    text-align: center;
    font-size: 28px;
    margin-top: 25px;
}

@media only screen and (max-width: 900px) {
    .loader-screen div {
        width: 45%;
    }
}

@media only screen and (max-width: 900px) {
    .loader-screen div h2 {
        font-size: 21px;
    }
}
